import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp, faArrowTrendDown, faMoneyBill, faBuildingColumns, faSortUp, faSortDown, faBolt, faHourglass3 } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-bootstrap';
import axios from 'axios';
import './DashboardPage.css';
import '../../assets/global.css';


function DashboardPage() {
    const navigate = useNavigate();
    const [allTradingCards, setallTradingCards] = useState([]);
    const [myTradingCards, setmyTradingCards] = useState([]);
    const [allNonActiveTradingCards, setAllNonActiveTradingCards] = useState([]);
    const [recentlyVisited, setRecentSignals] = useState([]);


    const lastTab = sessionStorage.getItem('LastTab');
    const lastSearchTerm = sessionStorage.getItem('LastSearchTerm');

    const [activeTab, setActiveTab] = useState(lastTab || 'AllTrades');

    const [userData, setUserData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortCriteria, setSortCriteria] = useState("lastUpdated");
    const [sortOrder, setSortOrder] = useState('descending');
    const [searchTerm, setSearchTerm] = useState(lastSearchTerm || '');

    const [filterType, setFilterType] = useState(null);
    const [filterDirection, setFilterDirection] = useState(null);
    const [filterDuration, setFilterDuration] = useState(null);

    const longPressTimeoutRef = useRef(null);


    const userId = sessionStorage.getItem('userId');
    const token = sessionStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            navigate("/login");
        }
    }, [token, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const headers = {
                    'Authorization': `Bearer ${token}`
                };

                const userDataResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/getUserData`, { headers });
                setUserData(userDataResponse.data);

                const allTradingCardsResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/AllTrades`, { headers });
                setallTradingCards(allTradingCardsResponse.data);

                const allNonActiveTradingCardsResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/AllTradesNonActive`, { headers });
                setAllNonActiveTradingCards(allNonActiveTradingCardsResponse.data);

                const myTradingCardsResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/MyTrades`, { headers });
                setmyTradingCards(myTradingCardsResponse.data);

                const recentSignalsResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/getRecentUniqueSignals`, { headers });
                setRecentSignals(recentSignalsResponse.data); 

            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Something went wrong getting your data or your session expired. Please log in again.");
            } finally {
                setLoading(false);
            }
        };


        fetchData();
    }, [userId, token]);

    const reLogin = () => {
        sessionStorage.clear();
        navigate('/login');
    }

    const sortSignals = (signals, order, sortCriteria) => {

        if (sortCriteria === 'lastUpdated') {
            return [...signals].sort((a, b) => {
                let comparison = 0;

                comparison = new Date(a.lastUpdated) - new Date(b.lastUpdated);

                if (order === 'descending') {
                    comparison = -comparison;
                }

                return comparison;
            });
        }
        if (sortCriteria === 'creationDate') {
            return [...signals].sort((a, b) => {
                let comparison = 0;

                comparison = new Date(a.creationDate) - new Date(b.creationDate);

                if (order === 'descending') {
                    comparison = -comparison;
                }

                return comparison;
            });
        }
        if (sortCriteria === 'closedDate') {
            return [...signals].sort((a, b) => {
                let comparison = 0;

                comparison = new Date(a.closedDate) - new Date(b.closedDate);

                if (order === 'descending') {
                    comparison = -comparison;
                }

                return comparison;
            });
        }

    };

    const searchSignals = (signals, term) => {

        if (!term.trim()) {
            return signals;
        }
        const upperCaseTerm = term.toUpperCase().trim();
        try {
            sessionStorage.setItem('LastSearchTerm', upperCaseTerm);
        } catch (error) {
        }
        const filteredSignals = signals.filter(signal =>
            signal.pair.toUpperCase().includes(upperCaseTerm) ||
            signal.id.toString().includes(upperCaseTerm)
        );
        return filteredSignals;
    };

    const filterSignals = (signals, filterType, filterDirection, filterDuration) => {

        return signals.filter(signal => {

            const matchesType = filterType ? signal.type === filterType : true;
            const matchesDirection = filterDirection ? signal.tradeDirection === filterDirection : true;
            const matchesDuration = filterDuration ? String(signal.isSwing) === filterDuration : true;
        
            return matchesType && matchesDirection && matchesDuration;
        })

    };

    // Function to handle right-click to clear the input
    const handleContextMenu = (event) => {
        event.preventDefault();
        setSearchTerm('');
        setFilterType(null);
        setFilterDirection(null);
        setFilterDuration(null);
        sessionStorage.removeItem('LastSearchTerm');
    };

    // Function to handle clearing the input
    const clearInput = () => {
        setSearchTerm('');
        setFilterType(null);
        setFilterDirection(null);
        setFilterDuration(null);
        sessionStorage.removeItem('LastSearchTerm');
    };

    // Function to handle touch start
    const handleTouchStart = () => {
        longPressTimeoutRef.current = setTimeout(clearInput, 500);
    };

    // Function to handle touch end
    const handleTouchEnd = () => {
        clearTimeout(longPressTimeoutRef.current); 
    };

    if (loading) return <div className="spinner">Loading...</div>;

    if (error) return (
        <div className="error-message">
            <p>Error: {error}</p>
            <button onClick={reLogin}>Retry</button>
        </div>
    );


    return (
        <div className="dashboard-container">
            <div className="main-content">
                <div className="sorting-controls">
                    <select onChange={(e) => setSortCriteria(e.target.value)} value={sortCriteria} className="sorting-select">
                        <option value="lastUpdated">Sort by Last Updated</option>
                        <option value="creationDate">Sort by Creation Date</option>
                        <option value="closedDate">Sort by Closed Date</option>
                    </select>
                    <div className="sorting-icons">
                        <button onClick={() => setSortOrder('ascending')} className={`sort-button ${sortOrder === 'ascending' ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faSortUp} />
                        </button>
                        <button onClick={() => setSortOrder('descending')} className={`sort-button ${sortOrder === 'descending' ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faSortDown} />
                        </button>
                    </div>

                    <div className="filter-icons">
                        <button
                            onClick={() => setFilterType(filterType === '1' ? null : '1')}
                            className={`filter-button ${filterType === '1' ? 'active' : ''}`}
                        >
                            <FontAwesomeIcon icon={faMoneyBill} title="Forex" />

                        </button>
                        <button
                            onClick={() => setFilterType(filterType === '2' ? null : '2')}
                            className={`filter-button ${filterType === '2' ? 'active' : ''}`}
                        >
                            <FontAwesomeIcon icon={faBuildingColumns} title="Indices" />
                        </button>
                        <button
                            onClick={() => setFilterDirection(filterDirection === 'buy' ? null : 'buy')}
                            className={`filter-button ${filterDirection === 'buy' ? 'active' : ''}`}
                        >
                            <FontAwesomeIcon icon={faArrowTrendUp} title="Buy Signal" />
                        </button>
                        <button
                            onClick={() => setFilterDirection(filterDirection === 'sell' ? null : 'sell')}
                            className={`filter-button ${filterDirection === 'sell' ? 'active' : ''}`}
                        >
                            <FontAwesomeIcon icon={faArrowTrendDown} title="Sell Signal" />
                        </button>
                        <button
                            onClick={() => setFilterDuration(filterDuration === '1' ? null : '1')}
                            className={`filter-button ${filterDuration === '1' ? 'active' : ''}`}
                        >
                            <FontAwesomeIcon icon={faHourglass3} title="Long-Term Trade" />
                        </button>
                        <button
                            onClick={() => setFilterDuration(filterDuration === '0' ? null : '0')}
                            className={`filter-button ${filterDuration === '0' ? 'active' : ''}`}
                        >
                            <FontAwesomeIcon icon={faBolt} title="Short-term Trade" />
                        </button>

                    </div>


                    <input
                        type="text"
                        placeholder="Search trade by pair or ID, right click to clear all filters."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onContextMenu={handleContextMenu}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                        className="sorting-input"
                    />
                </div>

                <Tabs activeTab={activeTab} setActiveTab={setActiveTab} userData={userData} />
                <TradingCardsContainer
                    activeTab={activeTab}
                    allTradingCards={sortSignals(searchSignals(filterSignals(allTradingCards, filterType, filterDirection, filterDuration), searchTerm), sortOrder, sortCriteria)}
                    weeklyReport={sortSignals(searchSignals(filterSignals(allNonActiveTradingCards, filterType, filterDirection, filterDuration), searchTerm), sortOrder, sortCriteria)}
                    myTradingCards={sortSignals(searchSignals(filterSignals(myTradingCards, filterType, filterDirection, filterDuration), searchTerm), sortOrder, sortCriteria)}
                    recentlyVisited={sortSignals(searchSignals(filterSignals(recentlyVisited, filterType, filterDirection, filterDuration), searchTerm), sortOrder, sortCriteria)}
                />
            </div>
        </div>
    );
}

function Tabs({ activeTab, setActiveTab, userData }) {

    const isCreatorOrAdmin = userData.roles[0] === 'Creator' || userData.roles[0] === 'Admin';

    return (
        <div className="tabs">

            <button className={activeTab === 'AllTrades' ? 'active' : ''} onClick={() => {
                setActiveTab('AllTrades');
                sessionStorage.setItem('LastTab', "AllTrades");
            }
            }>
                Active Trades
            </button>
            <button className={activeTab === 'WeeklyReport' ? 'active' : ''}
                onClick={() => {
                    setActiveTab('WeeklyReport');
                    sessionStorage.setItem('LastTab', "WeeklyReport");
                }
                }>
                Finished Trades
            </button>
            <button className={activeTab === 'RecentlyVisited' ? 'active' : ''}
                onClick={() => {
                    setActiveTab('RecentlyVisited');
                    sessionStorage.setItem('LastTab', "RecentlyVisited");
                }
                }>
                Recently Visisted
            </button>
            {isCreatorOrAdmin && (
                <button className={activeTab === 'MyTrades' ? 'active' : ''} onClick={() => {
                    setActiveTab('MyTrades');
                    sessionStorage.setItem('LastTab', "MyTrades");
                }
                }>
                    My Trades
                </button>
            )}
        </div>
    );
}

function TradingCardsContainer({ activeTab, allTradingCards, weeklyReport, myTradingCards, recentlyVisited }) {

    return (
        <div className="trading-cards-container">
            {
                activeTab === 'AllTrades' && allTradingCards.length === 0 ? (
                    <h1>There are no active trades yet.</h1>
                ) : (
                    activeTab === 'AllTrades' && (
                        allTradingCards.map((signal, index) => (
                            <AllTrades key={index} card={signal} />
                        ))
                    )
                )
            }
            {
                activeTab === 'MyTrades' && myTradingCards.length === 0 ? (
                    <h1>You have not created any trades yet.</h1>
                ) : (
                    activeTab === 'MyTrades' && (
                        myTradingCards.map((signal, index) => (
                            <MyTrades key={index} card={signal} />
                        ))
                    )
                )
            }
            {
                activeTab === 'WeeklyReport' && weeklyReport.length === 0 ? (
                    <h1>No trades have closed so far.</h1>
                ) : (
                    activeTab === 'WeeklyReport' && (
                        weeklyReport.map((signal, index) => (
                            <WeeklyReport key={index} card={signal} />
                        ))
                    )
                )
            }
            {
                activeTab === 'RecentlyVisited' && recentlyVisited.length === 0 ? (
                    <h1>You haven't visited any trades yet</h1>
                ) : (
                    activeTab === 'RecentlyVisited' && (
                        recentlyVisited.map((signal, index) => (
                            <RecentlyVisited key={index} card={signal} />
                        ))
                    )
                )
            }
        </div>
    );
}

function AllTrades({ card }) {

    const formatDate = (dateString) => {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };


    return (
        <NavLink
            to={`/card-view/${card.id}`}
            className={"Nav-Link"}
        >
            <div className='trading-card'>
                <h2>{card.pair}</h2>
                <p>Updated {formatDate(card.lastUpdated)}</p>
                <div className="icon-container">
                    {card.tradeDirection === 'buy' ? (
                        <Tooltip placement="top" title="Buy Signal">
                            <FontAwesomeIcon icon={faArrowTrendUp} className='trend-arrow' />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title="Sell Signal">
                            <FontAwesomeIcon icon={faArrowTrendDown} className='trend-arrow' />
                        </Tooltip>
                    )}
                    {card.type === "1" ? (
                        <Tooltip placement="top" title="Forex Trade">
                            <FontAwesomeIcon icon={faMoneyBill} className='trend-arrow' />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title="Indices Trade">
                            <FontAwesomeIcon icon={faBuildingColumns} className='trend-arrow' />
                        </Tooltip>
                    )}
                    {card.isSwing === 1 ? (
                        <Tooltip placement="top" title="Long-Term Trade">
                            <FontAwesomeIcon icon={faHourglass3} className='trend-arrow' />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title="Short-Term Trade">
                            <FontAwesomeIcon icon={faBolt} className='trend-arrow' />
                        </Tooltip>
                    )}
                </div>
            </div>
        </NavLink>
    );
}
function RecentlyVisited({ card }) {

    const formatDate = (dateString) => {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };


    return (
        <NavLink
            to={`/card-view/${card.id}`}
            className={"Nav-Link"}
        >
            <div className='trading-card'>
                <h2>{card.pair}</h2>
                <p>Updated {formatDate(card.lastUpdated)}</p>
                <div className="icon-container">
                    {card.tradeDirection === 'buy' ? (
                        <Tooltip placement="top" title="Buy Signal">
                            <FontAwesomeIcon icon={faArrowTrendUp} className='trend-arrow' />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title="Sell Signal">
                            <FontAwesomeIcon icon={faArrowTrendDown} className='trend-arrow' />
                        </Tooltip>
                    )}
                    {card.type === "1" ? (
                        <Tooltip placement="top" title="Forex Trade">
                            <FontAwesomeIcon icon={faMoneyBill} className='trend-arrow' />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title="Indices Trade">
                            <FontAwesomeIcon icon={faBuildingColumns} className='trend-arrow' />
                        </Tooltip>
                    )}
                    {card.isSwing === 1 ? (
                        <Tooltip placement="top" title="Long-Term Trade">
                            <FontAwesomeIcon icon={faHourglass3} className='trend-arrow' />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title="Short-Term Trade">
                            <FontAwesomeIcon icon={faBolt} className='trend-arrow' />
                        </Tooltip>
                    )}
                </div>
            </div>
        </NavLink>
    );
}

function WeeklyReport({ card }) {

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    return (
        <NavLink
            to={`/card-view/${card.id}`}
        
            className={"Nav-Link"}
        >
            <div className='trading-card'>
                <h2>{card.pair}</h2>
                <p>Closed {formatDate(card.closedDate)}</p>
                <div className="icon-container">
                    {card.tradeDirection === 'buy' ? (
                        <Tooltip placement="top" title="Buy Signal">
                            <FontAwesomeIcon icon={faArrowTrendUp} className='trend-arrow' />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title="Sell Signal">
                            <FontAwesomeIcon icon={faArrowTrendDown} className='trend-arrow' />
                        </Tooltip>
                    )}
                    {card.type === "1" ? (
                        <Tooltip placement="top" title="Forex Trade">
                            <FontAwesomeIcon icon={faMoneyBill} className='trend-arrow' />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title="Indices Trade">
                            <FontAwesomeIcon icon={faBuildingColumns} className='trend-arrow' />
                        </Tooltip>
                    )}
                    {card.isSwing === 1 ? (
                        <Tooltip placement="top" title="Long-Term Trade">
                            <FontAwesomeIcon icon={faHourglass3} className='trend-arrow' />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title="Short-Term Trade">
                            <FontAwesomeIcon icon={faBolt} className='trend-arrow' />
                        </Tooltip>
                    )}
                </div>
            </div>
        </NavLink>
    );
}

function MyTrades({ card }) {

    let takeProfit = card.takeProfit;
    const parsedTakeProfit = JSON.parse(takeProfit);
    const formatDate = (dateString) => {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    return (
        <NavLink to={`/editcard/${card.id}`} className={"Nav-Link"}>
            <div className='trading-card'>
                <h2>{card.pair}</h2>
                <p>Created {formatDate(card.creationDate)}</p>
                <p><b>Entry Price: </b>{card.entryPrice}</p>
                <p className='stop-loss-text'><b>Stop Loss: </b>{card.stopLoss}</p>
                {
                    parsedTakeProfit.map((tp, index) => (
                        <p key={index} className='take-profit-text'><b>Take Profit {index + 1}:</b> {tp}</p>
                    ))
                }
                <p><b>Exit price:</b> {card.exitPrice}</p>
            </div>
        </NavLink>
    );
}

export default DashboardPage;
