import React from "react";
import "./TraderChallange.css";

const mockTradesOpen = [
    {
        id: 211,
        pair: "XAUUSD CFD",
        creationDate: "2024-08-14T13:22:15.000Z",
        lastUpdated: "2024-08-14T13:22:15.000Z",
        tradeDirection: "sell",
        type: "1",
        isSwing: 1,
    },
    {
        id: 216,
        pair: "US500 CFD",
        creationDate: "2024-08-15T13:46:22.000Z",
        lastUpdated: "2024-08-15T13:46:22.000Z",
        tradeDirection: "sell",
        type: "2",
        isSwing: 1,
    },
    {
        id: 217,
        pair: "GER40 CFD",
        creationDate: "2024-08-22T18:00:37.000Z",
        lastUpdated: "2024-08-22T18:00:37.000Z",
        tradeDirection: "buy",
        type: "2",
        isSwing: 1,
    },
    {
        id: 218,
        pair: "SP500 CFD",
        creationDate: "2024-08-23T14:41:41.000Z",
        lastUpdated: "2024-08-23T14:41:41.000Z",
        tradeDirection: "sell",
        type: "2",
        isSwing: 1,
    },
];
const mockTradesClosed = [
    {
        id: 211,
        pair: "XAUUSD CFD",
        creationDate: "2024-08-14T13:22:15.000Z",
        lastUpdated: "2024-08-14T13:22:15.000Z",
        tradeDirection: "buy",
        type: "1",
        isSwing: 1,
    },
    {
        id: 216,
        pair: "US500 CFD",
        creationDate: "2024-08-15T13:46:22.000Z",
        lastUpdated: "2024-08-15T13:46:22.000Z",
        tradeDirection: "sell",
        type: "2",
        isSwing: 1,
    },
    {
        id: 217,
        pair: "GER40 CFD",
        creationDate: "2024-08-22T18:00:37.000Z",
        lastUpdated: "2024-08-22T18:00:37.000Z",
        tradeDirection: "sell",
        type: "2",
        isSwing: 1,
    },
    {
        id: 218,
        pair: "SP500 CFD",
        creationDate: "2024-08-23T14:41:41.000Z",
        lastUpdated: "2024-08-23T14:41:41.000Z",
        tradeDirection: "buy",
        type: "2",
        isSwing: 1,
    },
];

let starting = 200000;


let profit = 7500;
let loss = 8000;

let netProfit = profit - loss;
let current = starting + netProfit;

const TraderProgress = () => {
    return (
        <div className="progress-container">
            <div className="grid">
                <div className="card">
                    <h2>Capital Overview</h2>
                    <div className="stat-group">
                        <p>Starting: <span>${starting}</span></p>
                        <p>Current: <span className="highlight">${current}</span></p>
                        <p>Net Profit: <span className="profit" style={{ color: netProfit > 0 ? 'green' : netProfit < 0 ? 'red' : 'gray' }}>
                            {netProfit > 0 ? `+$${netProfit}` : `-$${netProfit}`}
                        </span></p>

                        <p>Profit: <span className="profit">+${profit}</span></p>
                        <p>Loss: <span className="loss">-${loss}</span></p>
                    </div>
                </div>

                <div className="card">
                    <h2>Program Status</h2>
                    <div className="stat-group">
                        <p>Stage: <span>1</span></p>
                        <p>Goal: <span>$270,000</span></p>
                        <p>Max Daily Loss: <span>5%</span></p>
                        <p>Termination: <span>$195,000</span></p>
                        <p>Time Left: <span className="time-left">28 days</span></p>
                    </div>
                    <button className="end-btn">End Early</button>
                </div>
            </div>

            {/* Open Trades Section */}
            <div className="positions-section">
                <h2>Open positions</h2>
                <div className="trades-list">
                    {mockTradesOpen.map((trade) => (
                        <div key={trade.id} className={`trade-item ${trade.tradeDirection}`}>
                            <span className="trade-pair">{trade.pair}</span>
                            <span className={`trade-direction ${trade.tradeDirection}`}>
                                {trade.tradeDirection.toUpperCase()}
                            </span>
                            <span className="trade-date">
                                {new Date(trade.creationDate).toLocaleDateString()}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="positions-section">
                <h2>Closed positions</h2>
                <div className="trades-list">
                    {mockTradesClosed.map((trade) => (
                        <div key={trade.id} className={`trade-item ${trade.tradeDirection}`}>
                            <span className="trade-pair">{trade.pair}</span>
                            <span className={`trade-direction ${trade.tradeDirection}`}>
                                {trade.tradeDirection.toUpperCase()}
                            </span>
                            <span className="trade-date">
                                {new Date(trade.creationDate).toLocaleDateString()}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TraderProgress;
