import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import axios from 'axios';
import './ProfilePage.css';
import PaymentPage from '../PaymentPage/PaymentPage';

function ProfilePage() {
    const navigate = useNavigate();

    const [userData, setUserData] = useState({});
    const [userNotifyPref, setUserNotifyPref] = useState(3);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [selectedNotifyPref, setSelectedNotifyPref] = useState(3);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const token = sessionStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
    
                // Fetch user profile
                const userDataResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/getUserProfile`, { headers });
                setUserData(userDataResponse.data);
    
                // Fetch user notification preferences
                const userNotifyPref = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/getNotifyPref`, { headers });
                setUserNotifyPref(userNotifyPref.data);
                console.log(userNotifyPref);
    
                // Set initial selected notification preference
                if (userNotifyPref.data.length > 0) {
                    setSelectedNotifyPref(userNotifyPref.data[0].type);
                }
    
                
    
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
    
        fetchUserData();
    }, []);
    

    const handleNotifyPrefChange = (event) => {
        setSelectedNotifyPref(parseInt(event.target.value));
    };

    console.log(userNotifyPref);

    // Function to save preferences
    const savePreferences = async (e) => {
        e.preventDefault();
        try {
            const token = sessionStorage.getItem('token');
            const headers = { 'Authorization': `Bearer ${token}` };

            await axios.post(`https://trading-website-5b1479c37ccb.herokuapp.com/updateNotifyPref`,
                { type: selectedNotifyPref },
                { headers }
            );

            alert("Preferences saved!");
        } catch (error) {
            alert("Error saving preferences.");
        }
    };


    let formattedRegistrationDate;
    if (userData && userData.user && userData.user.registrationDate) {
        const registrationDate = new Date(userData.user.registrationDate);
        formattedRegistrationDate = registrationDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    }

    const reLogin = () => {
        sessionStorage.clear();
        navigate('/login');
    }

    if (loading) return <div className="spinner">Loading...</div>;
    if (error) return (
        <div className="error-message">
            <p>Error: {error}</p>
            <button onClick={reLogin}>Retry</button>
        </div>
    );



    const userEmail = () => {
        let link = "https://billing.stripe.com/p/login/14kbLq3VJ7pOa6A000?prefilled_email=";
        if (userData.user.email) {
            let email = userData.user.email;
            let emailParts = email.split('@');
            let emailDomain = emailParts[1];
            let emailUsername = emailParts[0];
            link += emailUsername + "%40" + emailDomain;
            return <div className='submit-btn-profile'><NavLink to={link}>Manage Subscriptions</NavLink></div>;
        }
        return '';
    };

    return (
        <div className="profile-container">
            <h1>User Profile</h1>
            <div className="user-details">
                <p><strong>Username:</strong> {userData.user.username}</p>
                <p><strong>Email:</strong> {userData.user.email}</p>
                <p><strong>Phone Number:</strong> {userData.user.phoneNumber || "N/A"}</p>
                <p><strong>Registration Date:</strong> {formattedRegistrationDate}</p>
                {userData.subscriptions && userData.subscriptions.length > 0 && (
                    <p><strong>Subscription Type:</strong> {userData.subscriptions[0].typeName}</p>
                )}
            </div>

            <div className="user-preferences">
                <h2>User Preferences</h2>
                <form onSubmit={savePreferences}>
                    {/* Notification Frequency */}
                    <div className="preference-item">
                        <label>Allow Email updates for Trades:</label>
                        <div className="frequency-options">
                            <input type="radio" id="no-notifications" name="notificationFrequency" value="0"
                                checked={selectedNotifyPref === 0} onChange={handleNotifyPrefChange} />
                            <label htmlFor="no-notifications">No Notifications</label>

                            <input type="radio" id="only-added" name="notificationFrequency" value="1"
                                checked={selectedNotifyPref === 1} onChange={handleNotifyPrefChange} />
                            <label htmlFor="only-added">Only When Added</label>

                            <input type="radio" id="only-updates" name="notificationFrequency" value="2"
                                checked={selectedNotifyPref === 2} onChange={handleNotifyPrefChange} />
                            <label htmlFor="only-updates">Only on Updates</label>

                            <input type="radio" id="all" name="notificationFrequency" value="3"
                                checked={selectedNotifyPref === 3} onChange={handleNotifyPrefChange} />
                            <label htmlFor="all">All Notifications</label>
                        </div>
                    </div>

                    <button className="submit-btn-profile" type="submit">Save Preferences</button>
                </form>
            </div>





            <div className="subscription-actions">
                <h2>Subscriptions</h2>
                <PaymentPage />
                {userEmail()}
            </div>
        </div>
    );
}

export default ProfilePage;
